import { DELETE_PERMISSION, CONTAINER_FILLING_ITEM } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Item", "search-by": "Code,Description", "ph-search-by": "Search by Item Code, Description" }, scopedSlots: _vm._u([{ key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    var toggleActive = ref.toggleActive;
    return [_vm.can(_vm.permissions.delete) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v(_vm._s(record.isInactive ? "Activate" : "Deactivate"))];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "list__action-toggle-active mr-2", attrs: { "icon": record.isInactive ? "eye-invisible" : "eye", "size": "small", "type": "link" }, on: { "click": function($event) {
      return toggleActive(record);
    } } })], 1) : _vm._e(), _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Detail")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "right-circle", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1)];
  } }]) }, [_c("url-field", { key: "Code", attrs: { "base-url": "/container-filling/item", "data-index": "code", "title": "Item Code", "width": 150, "sorter": true } }), _c("text-field", { key: "Description", attrs: { "data-index": "description", "title": "Description", "width": 320 } }), _c("text-field", { key: "StockUoM", attrs: { "data-index": "stockUoM", "title": "Stock UoM", "width": 115, "sorter": true } }), _c("text-field", { key: "SpecificationCode", attrs: { "data-index": "specificationCode", "title": "Specification", "width": 150, "sorter": true } }), _c("text-field", { key: "SpecificationDesc", attrs: { "data-index": "specificationDesc", "title": "Specification Description", "width": 320 } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListItem",
  inject: ["can"],
  data() {
    return {
      permissions: {
        delete: DELETE_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListItem = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-item" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "cf.items", "api-url": _vm.apiUrl, "create-route": "/container-filling/item/create", "edit-route": "/container-filling/item/:id", "page": _vm.page } }, [_c("list-item")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListItem
  },
  data() {
    return {
      page: CONTAINER_FILLING_ITEM,
      ListItem,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "item",
          title: "Item",
          path: "/container-filling/item"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
